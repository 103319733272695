import { observer } from "mobx-react";
import React from "react";
import { Accordion } from "../../shared/components/accordion/AccordionView";
import { imageCropMock, imageHotspotMock } from "../../shared/components/image/Image.mock";
import { List } from "../../shared/components/list/List";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { PortableTextContent } from "../../shared/components/portableTextContent/PortableTextContent";
import { useTranslations } from "../../shared/contexts/StaticTranslationContext";
import alternativeImage from "../../static/images/termsAndConditionsPlaceholder-milg31nitn13oi93-700x525-png.png.png";
import { ArticleHero } from "../articles/article/articleHero/ArticleHero";
import { TermsAndConditionsViewModel } from "./TermsAndConditionsViewModel";

interface TermsAndConditionsViewProps {
  model: TermsAndConditionsViewModel;
}

export const TermsAndConditionsView: React.FC<TermsAndConditionsViewProps> = observer(props => {
  const { model } = props;
  const translations = useTranslations();

  if (!model.termsAndConditionsInfo || !model.termsAndConditionsAccordion) {
    return null;
  }

  const { description, image, title } = model.termsAndConditionsInfo;

  return (
    <PageTitle title={translations.termsAndConditions}>
      <div className="TermsAndConditions">
        <ArticleHero
          title={title}
          description={description}
          image={
            image || {
              url: alternativeImage,
              alt: "",
            }
          }
        />
        <div className="TermsAndConditions__wrapper">
          <div className="TermsAndConditions__content">
            <List
              items={model.termsAndConditionsAccordion}
              className="TermsAndCondition__accordionsList"
              singleItemClassName="TermsAndConditions__accordion"
              renderItem={acc => (
                <Accordion
                  title={acc.title}
                  content={<PortableTextContent content={acc.description} />}
                  model={acc.model}
                  variant="dark"
                />
              )}
            />
          </div>
        </div>
      </div>
    </PageTitle>
  );
});
