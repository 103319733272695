import uniqBy from "lodash/uniqBy";
import { action, computed, observable } from "mobx";
import { BackendApi, IProductArticleService } from "../../../shared/api/BackendApi";
import { SelectViewModel } from "../../../shared/components/select/SelectViewModel";
import { Url } from "../../../shared/models/Url";
import { IErrorService } from "../../../shared/services/ErrorService";
import { IGtmService } from "../../../shared/services/GtmService";
import { ILanguageService } from "../../../shared/services/LanguageService";
import { ILowLevelNavigationService } from "../../../shared/services/NavigationService";
import { Product } from "../../../shared/stores/Product";
import { UserStore } from "../../../shared/stores/UserStore";
import { isDefined } from "../../../shared/typeUtils";
import { ArticleListItemDto } from "../../../types/articles/dto/ArticleListItemDto";
import { GtmBasePage } from "../../GtmBasePage";

export class ArticleListViewModel extends GtmBasePage {
  allCategoriesId = "all-categories";

  @observable private _articles: ArticleListItemDto[] = [];

  @observable private categories: (string | undefined)[] = [];

  @observable private _category: string | undefined;

  @observable categoriesSelect?: SelectViewModel<string>;

  @observable isMobile = false;

  @computed get category() {
    return this._category;
  }

  @computed get articles() {
    if (!this.category) {
      return this._articles;
    }

    if (this.category === this.allCategoriesId) {
      return this._articles;
    }

    return this._articles.filter(article => article.category === this.category);
  }

  @action.bound
  changeFilter() {
    const url = Url.toProductArticleListPage({ productSlug: this.product.slug }, { category: this.category });
    this.navigationService.pushUrlWithoutRedirect(url);
  }

  constructor(
    private defaultFiltersValue: {
      categorySlug?: string;
    },
    public languageService: ILanguageService,
    public errorService: IErrorService,
    public navigationService: ILowLevelNavigationService,
    public gtm: IGtmService,
    public handleLogin: (slug?: string) => Promise<void>,
    public userStore: UserStore,
    public product: Product,
    public backendApi: BackendApi,
    private productArticleService: IProductArticleService
  ) {
    super(languageService, gtm, errorService);
    this.checkIfMobile = this.checkIfMobile.bind(this);
    window.addEventListener("resize", this.checkIfMobile);
    this.checkIfMobile();
  }

  @action.bound
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  protected async loadData() {
    if (this.childPage) {
      return;
    }

    // TODO: After adding pagination we should make a separate query after category filter change, and use it for the query
    this._articles = await this.productArticleService.getProductArticles({
      productId: this.product.id,
    });

    this.categories = Array.from(new Set(this._articles.map(a => a.category)));

    const defaultCategoryId = this.defaultFiltersValue.categorySlug
      ? this.categories.find(category => category === this.defaultFiltersValue.categorySlug)
      : undefined;

    this.categoriesSelect = new SelectViewModel(
      this.categories.map(category => ({
        value: category || "unknown",
        label: category || "Unknown Category",
      })),
      defaultCategoryId ? [defaultCategoryId] : [this.allCategoriesId],
      this.changeFilter
    );
  }
}
